<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Host history settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'host\'s'"
              :total="dataTotal"
              :fetch-path="'hostHistories/fetch'"
              :filter-path="'hostHistories/filter'"
            >
              <!-- :export-path="'hostHistories/export'" -->
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        v-if="loggedUser.roles.includes('super-admin')"
                        @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})"
                      >
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item
                        v-if="field.agreement"
                        @click="showDocument(field.agreement)"
                      >
                        <i
                          class="me-50"
                          data-feather="book-open"
                        /> View Agreement
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="field.annex && field.annex.url"
                        @click="showDocument(field.annex.url)"
                      >
                        <i
                          class="me-50"
                          data-feather="book-open"
                        /> View Annex
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item
                        v-if="loggedUser.roles.includes('super-admin')"
                        @click="$router.push({name: 'researcher.administration.host-history.edit', params: { id: field.id }})"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="loggedUser.roles.includes('super-admin')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Institution</label>
                  <v-select
                    label="name"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                    @input="saveFilter($event, 'institution')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Collaboration agreements</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'agreement')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Annex</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'anex')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <p><strong>Begin date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['begin_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['begin_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date_to')"
                  />
                </div>
                <p><strong>End date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['end_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['end_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'end_date_to')"
                  />
                </div>
                <p><strong>Signature date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters['signature_date_from']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'signature_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters['signature_date_to']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'signature_date_to')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      filters: [],
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Host Institution',
          checked: true,
          order: 2,
        },
        {
          name: 'Department',
          checked: true,
          order: 3,
        },
        {
          name: 'Has 2nd institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Current',
          checked: true,
          order: 5,
        },
        {
          name: 'Interlocutor',
          checked: true,
          order: 6,
        },
        {
          name: 'Status',
          checked: true,
          order: 7,
        },
        {
          name: 'Dedication',
          checked: true,
          order: 8,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 9,
        },
        {
          name: 'End date',
          checked: true,
          order: 10,
        },
        {
          name: 'Agreement',
          checked: true,
          order: 11,
        },
        {
          name: 'Annex',
          checked: true,
          order: 12,
        },
        {
          name: 'Signature date',
          checked: true,
          order: 13,
        },
        {
          name: 'Current evaluator',
          checked: true,
          order: 13,
        },
      ],
      sortMapping: {
        Researcher: 'user',
        'Host Institution': 'host_institution_code',
        Department: 'department1',
        'Has 2nd institution': 'has_second_host',
        Interlocutor: 'interlocutor',
        Status: 'status',
        Dedication: 'dedication_table',
        'Begin date': 'begin_date',
        'End date': 'end_date_table',
        Agreement: 'agreement_table',
        Current: 'current',
        Annex: 'annex_table',
        'Signature date': 'signature_date',
        'Current evaluator': 'evaluator',
      },
      title: 'Host\'s history',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'hostHistories/items',
      dataTotal: 'hostHistories/itemsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      hosts: 'institutions/institutions',
      filtersAux: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'host_histories')
    await this.$store.dispatch('hostHistories/filter', this.filtersAux)
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'host_histories',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'hostHistories', url: `${Vue.prototype.$groupUrl}/host-histories/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    showDocument(url) {
      window.open(url, '_blank')
    },
  },
}
</script>
